/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, TitleMain, Subtitle, Text, Title, YouTube } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nas"}>
        <ColumnCover name={"g5a9uwv0uq"}>
          
          <Column className="--menu pb--12 pt--12" anim={null} menu={true} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
            
            <Menu className="--full" fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box pl--06">
                
                <Image className="--left" alt={"Logo PROFICOMFORT – infratopení"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} url={"https://proficomfort.cz"} use={"url"} href={"https://proficomfort.cz"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":240}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} target={null}>
                </Image>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/"} target={""} content={"Úvod"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} target={""} content={"O NÁS"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} target={null} content={"INFRAPANELY"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={""} content={"PRODUKTY"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01 pr--10" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} target={""} content={"KE STAŽENÍ"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 fs--16 w--900 swpf--uppercase ls--01" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} target={""} content={"KONTAKT"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="mt--60 pb--60 pt--60" name={"szq16opjo9n"} style={{"backgroundColor":"rgba(216,36,36,1)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <TitleMain className="title-box w--900 title-box--invert" content={"Proficomfort a infratopení "}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--80" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Historie"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Již od začátku roku 2005 pro Vás zajišťujeme návrhy a dodávky nízkoenergetických infračervených topných systémů. <a href=\"/\">Infratopení</a> dodáváme jak pro kanceláře, byty, tak i rodinné domy. Na počátku roku 2006 jsme v České republice začali dodávat nové nízkoteplotní infračervené sálavé panely - INFRAHEATING. V roce 2010 jsme se rozhodli náš sortiment rozšířit a přidali jsme nové produkty vysokoteplotních infračervených zářičů. Tyto infrapanely jsou vhodné pro vytápění restaurací, výrobních, obchodních nebo skladovacích prostor. Naše firma je tedy schopna navrhnout a realizovat řešení pro vytápění jakéhokoliv prostoru. V současnosti nabízíme v oblasti infratopení široký sortiment topných prvků. V roce 2019 infračervené topné panely prošly kompletním redesignem a získali nový certifikát. Nyní nesou název a logo PROFICOMFORT – infračervené teplo domova. To je úspěch kvality českého výrobce.&nbsp;&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Za dobu fungování naší firmy jsme získali potřebné zkušenosti, a tak jsme schopni Vám odborně poradit ohledně veškeré problematiky v oblasti infratopení a infračervených zářičů. Realizovali jsme <a href=\"/#szq16opjo9n\">infratopení</a> v obytných budovách jako jsou rodinné domy, byty, popř. kompletní bytové domy. Bohaté zkušenosti máme i v komerčních a výrobních prostorách.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-10i2o0w bg--center --parallax pb--80 pt--80" name={"31m9acmz1vl"} style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/26c4e39a1ea8408ab8f814caec5e2989_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 mb--10 mt--10" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --center pb--10" anim={"5"} animS={"5"} style={{"maxWidth":1440}}>
              
              <Title className="title-box title-box--invert" content={"Vyberte si z naši široké nabídky"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 w--900 swpf--uppercase ls--0" href={"/"} content={"<span style=\"color: rgb(255, 255, 255);\">Prohlížet produkty</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--80" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Služby firmy Proficomfort v oblasti infratopení\n\n"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Cenové návrhy ZDARMA</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Poradenství v obore <a href=\"/\">infratopení</a> přímo u Vás</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Bohaté zkušenosti s realizací (15 let)</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Vysoká kvalita a dlouhá životnost infrapanelů</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Doručení ZDARMA</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Zajištění montáže infrazářičů i elektroinstalace</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Zajištění revize infračerveného topného systému</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500 lh--2 mt--06" content={"<span style=\"color: rgb(216, 36, 36);\">►</span>&nbsp; &nbsp;<span style=\"color: rgb(78, 80, 97);\">Záruční a pozáruční servis včetně náhradních dílů</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" content={"Proč si vybrat k realizaci infratopení právě Proficomfort ?\n\n"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2" content={"<span style=\"color: rgb(78, 80, 97);\">Vzhledem k více než 15 leté historii firmy Proficomfort jsme schopni nalézt řešení vytápění infračervenými topnými panely pro jakýkoliv obytný či komerční objekt. Máme dnes mnoho spokojených zákazníků z celé ČR ale i v zahraničí. Naše <a href=\"/infrapanely-do-domacnosti\">infrapanely</a> zlepšují tepelný komfort v mnoha rodinných domech, kancelářích, prodejnách, výrobních prostorách včetně velkých objektů, jako jsou bytové a obchodní domy.\n\nPřesvědčte se o naší odbornosti a kvalitě v oblasti infračerveného topení. Podívejte se na naše reference a rozšiřte řady našich spokojených zákazníků.\n\nInfratopení je zdravé, čisté, komfortní, hygienické, tiché a příjemné. Sálavé infrapanely mají snadnou regulaci díky termostatu. Velkou výhodou infrapanelů je dosažitelnost zdroje elektrické energie. Provozní náklady vytápění infra topnými panely jsou jedny z nejnižších.&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5" anim={"4"} animS={"5"} style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --shape5 --left el--1 flex--top" style={{"maxWidth":1440,"backgroundColor":"rgba(251, 250, 255, 1)"}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s5 mb--0 mt--0 pt--0" anim={"4"} animS={"5"} style={{"maxWidth":""}} border={null}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"w8hd4n4ah4f"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box" content={"Infratopení na Slunečným Vrchu"}>
              </Subtitle>

              <Text className="text-box fs--18 w--500 lh--2 mb--80" style={{"maxWidth":780}} content={"<span style=\"color: rgb(78, 80, 97);\">Infratopení na Slunečným Vrchu – v bytech je instalován systém moderního a nízkonákladového vytápění. Úspora energie infratopením je, mimo jiné, dosahována tím, že teplota vzduchu proti běžným tepelným systémům, při zachování stejné tepelné pohody, může být snížena o 2 až 4 °C. Infratopení ohřívá nejprve zdi a předměty v místnosti a od nich se teprve ohřívá vzduch. K víření vzduchu jeho ohřevem prakticky nedochází. Rozdíl teplot mezi podlahou a stropem je maximálně do 2°C.\n\n</span>"}>
              </Text>

              <YouTube embedId={"sJflI6iiltw"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left mt--60 pb--80 pt--60" name={"szq16opjo9n"} style={{"backgroundColor":"rgba(216,36,36,1)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--900 title-box--invert" content={"Infratopení FAQ"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center subtitle-box--invert mt--06" content={"Časté otázky a dotazy "}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--50 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Jaký je rozdíl mezi infrapanely základní ITZ a skleněný ITS\n\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Základní panely ITZ mají bílý hladky povrch  (bílá folie), a jejich emisivita sálavosti není tak účinná jako u standardních skleněných panelů ITS. Standardní skleněné panely ITS mají přibližně o 8 % lepší sálavost. Obrazky jsou vypálovány do skla a kalené až po tisku, což zvyšuje kvalitu a zaručuje barevnou stálost.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Pozor na extrémně levné výrobky infračervených topných systémů!\n\n\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Základní panely ITZ mají bílý hladky povrch  (bílá folie), a jejich emisivita sálavosti není tak účinná jako u standardních skleněných panelů ITS. Standardní skleněné panely ITS mají přibližně o 8 % lepší sálavost. Obrazky jsou vypálovány do skla a kalené až po tisku, což zvyšuje kvalitu a zaručuje barevnou stálost.\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Jaká je životnost infrapanelů?\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Topný článek je uhlíkové desky (karbonu) životnost této topné plochy je 40 - 50 let. \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Po zdravotní stránce!\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Teplota na povrchu infračerveného topného systému, musí být v rozmezí 90 - 100°C (jedná se o topné systémy určené do bytů). Infrapanely s větší teplotou by měly být umísťovány ve větší výšce (např. vytápění velkých hal a provozních objektů).Proto si nechte poradit od Vašeho prodejce a chtějte kvalitu a jistotu provozu.\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Jsou infra topné panely vhodné pro rostliny a domácí zvířata?\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Infračervené topné panely vyzařují vlnové délky, které jsou v minimální spodní hranici vlnového rozsahu IR vln, a ty jsou pro lidskou tkáň a oko neškodné, stejně tak pro rostliny i zvířata. Poznatky z praxe ukazují, že zvířata si infratopení oblíbí a zdržují se v jeho nejbližším dosah\n\n\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Kam mohu infrapanely umístit?\n\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Infračervené topné panely lze umístit na každou stěnu v uzavřené místnosti, nebo na strop. Na strop je vhodné infrapanely umístnit, pokud je místnost obestavěná nábytkem. Infrapanely by něměli být přímo proti oknům. Nechte si poradit o našeho prodejce. Spolu s Vámi najdeme vhodné umístnění infrapanelů.\n\n\n\n\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Je možné se během provozu infrapanelů dotýkat ?\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" V provozu infračervené topné panely dosahují teploty 100°C, ale na chvilkový dotyk je panel bezpečný. Jelikož povrch panelů je nekovový (nevodivý) , nehrozí v případě dotyku žádné nebezpečí.\n\n\n\n\n\n\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Jaký mají infrapanely Infraheating příkon?\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={"Infrapanely Infraheating mají příkony 350W, 500W, 600W, 750W a 900W (+-10%)."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Je vhodná instalace infratopení na chalupy, která není v zimním období užívána pravidelně.\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Ano, infratopení je velice vhodné na chalupách temperovat. Na termostatu jednoduše nastavíte temperační teplotu  a teplota pevných materiálů či zdí, se bude udržovat o 1-2°C vyšší.\n\n\n\n\n\n\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={"Je opravdu výhodné pořídit si infratopení?\n\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Dimenzovaný příkon infratopení je poloviční oproti jiným druhů klasického elektrického topení. Co se týče spotřeby, stěna udrží mnohem déle teplo v místnosti než-li vzduch a tepelná enerie se tak rychle neztrácí. Pokud vyvětráte, klasickým topením musíte znovu ohřát znovu vzduch. Od infratopení je vše nádherně prohřáté a během malé chvilky, je tepelná pohoda zase zpět. \n\n\n\n\n\n\n\n\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30 flex--top" style={{"maxWidth":1200}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":478}}>
              
              <Subtitle className="subtitle-box subtitle-box--invert mt--06" style={{"maxWidth":390}} content={" Infratopení a sádrokarton?\n\n\n\n"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box fs--18 text-box--invert mt--06" content={" Sádrokartonové stěny je možné vytápět infratopením. Nesmí ale nikde vzniknout vzduchové komory. Je nutné je vždy vyplnit například izolací.  \n\n \n\n\n\n\n\n\n\n\n\n \n\n\n\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"szq16opjo9n"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1440}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 --left pl--20 pr--20" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"Pokud Vás zajímají otázky ohledně vytápění nebo infratopení, které zde nebyli zodpovězeny napište na info@proficomfort.cz\n\n"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1knc10f bg--center --parallax pb--60 pt--60" style={{"backgroundColor":"rgba(25, 24, 30, 1)"}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/11212/b87288ca794a4a61a644de1ef496722b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--3 mb--40 mt--25" style={{"maxWidth":1440}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Logo – infratopení – PROFICOMFORT"} src={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=350x_.png 350w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=660x_.png 660w, https://cdn.swbpg.com/t/11212/15e63bba01894a8e8c8451ec197efb78_s=860x_.png 860w"} content={null}>
              </Image>

              <Text className="text-box fs--18 w--600 text-box--invert mt--25" content={"<span style=\"color: rgb(127, 129, 150);\">Hosov 65<br>586 01 Jihlava</span><br>"}>
              </Text>

              <Text className="text-box fs--18 w--600 text-box--invert mt--10" content={"<span style=\"color: rgb(127, 129, 150);\">+420 776 117 775<br>nekula@proficomfort.cz</span><br>"}>
              </Text>

              <Image className="pl--06" alt={""} src={"https://cdn.swbpg.com/o/11212/44bad18f84e0428187e9d7bd02eaea99.svg"} svg={false} href={"https://www.facebook.com/proficomfort/"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":27}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">další Produkty</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" url={"https://proficomfort.cz/infrapanely-do-domacnosti/"} use={"url"} href={"https://proficomfort.cz/infrapanely-do-domacnosti/"} content={"<span style=\"color: rgb(127, 129, 150);\">Infrapanely do domácnosti</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/elektricke-podlahove-topeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Elektrické podlahové topení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/prumyslove-salave-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Průmyslové sálavé zářiče</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/halogenove-zarice"} content={"<span style=\"color: rgb(127, 129, 150);\">Halogenové zářiče</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":344}}>
              
              <Subtitle className="subtitle-box mb--30" content={"<span style=\"color: rgb(255, 255, 255);\">Navigace</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/"} content={"<span style=\"color: rgb(127, 129, 150);\">Úvod</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"https://proficomfort.cz/#produkty"} target={null} content={"<span style=\"color: rgb(127, 129, 150);\">Produkty</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/o-nas"} content={"<span style=\"color: rgb(127, 129, 150);\">O nás</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/ke-stazeni"} content={"<span style=\"color: rgb(127, 129, 150);\">Ke stažení</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"/kontakt"} content={"<span style=\"color: rgb(127, 129, 150);\">Kontakt</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--cColor1 btn-box--filling2" innerClassName="pb--02 pl--0 pr--0" href={"https://cdn.swbpg.com/d/37846/gdpr.pdf"} content={"<span style=\"color: rgb(127, 129, 150);\">GDPR</span><br>"} use={"file"} document={"13b986599b3d4928a142c6ca75a3df4a"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--10 pt--10" anim={null} animS={null} style={{"backgroundColor":"rgba(216,36,36,1)"}} border={null}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":1440}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left fs--14 w--600 text-box--invert" content={"<a href=\"https://saywebpage.com/cs/\" style=\"color: rgb(255, 255, 255);\">Tvorba webových stránek</a>: saywebpage.com<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--right fs--18 w--600 text-box--invert" content={"2024<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}